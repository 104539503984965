
import { Options, Vue } from "vue-property-decorator";
import api from "@/api/equipmenttest";
import { parseTime } from "@/utils";

@Options({})
export default class extends Vue {
  private active = 0;
  private show1 = false;
  private show2 = false;
  private show3 = false;
  private lbfhdetail = {};
  date1: any = "";
  date2: any = "";
  situation: any = null;
  private listData: any[] = [];
  private refreshing = false;
  private loading = false;
  private finished = false;
  private resultPickerscolumns: any[] = [
    {
      id: "0",
      name: "恢复",
    },
    {
      id: "1",
      name: "缺失",
    },
  ];
  private searchForm = {
    warnType: null,
    startTime: null,
    endTime: null,
    pageNum: 1,
    pageSize: 10,
    total: 0,
    dirId: "",
  };

  mounted() {
    this.lbfhdetail = this.$store.state.project.lbfhdetail;
    this.searchForm.dirId = this.$store.state.project.projectinfoDetail.id;
  }
  onLoad() {
    setTimeout(() => {
      if (this.refreshing) {
        this.listData = [];
        this.searchForm.pageNum = 1;
        this.refreshing = false;
      }
      this.searchForm.dirId = this.$store.state.project.projectinfoDetail.id;

      api.lbfhpage(this.searchForm).then((res: any) => {
        this.listData = [...this.listData, ...res.data.records];
        this.searchForm.pageNum++;
        this.loading = false;
        if (this.listData.length >= res.data.total) {
          this.finished = true;
        }
      });
    }, 1000);
  }

  onRefresh() {
    this.finished = false;
    this.loading = true;
    this.onLoad();
  }

  openPopup1() {
    this.show1 = true;
  }
  openPopup2() {
    this.show2 = true;
  }
  openPopup3() {
    this.show3 = true;
  }
  resultPickersonConfirm(val: any) {
    this.situation = val.name;
    this.searchForm.warnType = val.id;
    this.show3 = false;
  }
  fConfirmDate1(val: any) {
    this.date1 = parseTime(val, "{y}-{m}-{d} {h}:{i}");
    this.searchForm.startTime = this.date1;
    this.show1 = false;
  }
  fConfirmDate2(val: any) {
    this.date2 = parseTime(val, "{y}-{m}-{d} {h}:{i}");
    this.searchForm.endTime = this.date2;
    this.show2 = false;
  }
  reset() {
    this.searchForm = {
      warnType: null,
      startTime: null,
      endTime: null,
      pageNum: 1,
      pageSize: 10,
      total: 0,
      dirId: "",
    };
    this.date1 = "";
    this.date2 = "";
    this.situation = "";
    this.refreshing = true;
    this.onRefresh();
  }
  searchResult() {
    if (this.date1 && this.date2 === "") {
      this.$toast.fail("请选择结束时间");
      return;
    }
    if (this.date2 && this.date1 === "") {
      this.$toast.fail("请选择开始时间");
      return;
    }
    this.refreshing = true;
    this.onRefresh();
  }
}
